import './App.css';
import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import { SketchPicker } from 'react-color'
import { io } from 'socket.io-client';

function App() {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSetPassword = (e) => {
    setPassword(e.target.value);
  }

  const [debugCount, setDebugCount] = React.useState(1);
  const handleSetDebugCount = (e) => {
    setDebugCount(e.target.value);
  }

  const [startHours, setStartHours] = React.useState(1);
  const handleSetStartHours = (e) => {
    setStartHours(e.target.value);
  }
  const [startMinutes, setStartMinutes] = React.useState(1);
  const handleSetStartMinutes = (e) => {
    setStartMinutes(e.target.value);
  }
  const [mainJackpot, setMainJackpot] = React.useState(500);
  const handleSetMainJackpot = (e) => {
    setMainJackpot(e.target.value);
  }
  const [secondJackpot, setSecondJackpot] = React.useState(400);
  const handleSetSecondJackpot = (e) => {
    setSecondJackpot(e.target.value);
  }
  const [thirdJackpot, setThirdJackpot] = React.useState(300);
  const handleSetThirdJackpot = (e) => {
    setThirdJackpot(e.target.value);
  }
  const [fourthJackpot, seFourthJackpot] = React.useState(200);
  const handleSetFourthJackpot = (e) => {
    seFourthJackpot(e.target.value);
  }
  const [fifthJackpot, setFifthJackpot] = React.useState(100);
  const handleSetFifthJackpot = (e) => {
    setFifthJackpot(e.target.value);
  }
  const [startSeconds, setStartSeconds] = React.useState(1);
  const handleSetStartSeconds = (e) => {
    setStartSeconds(e.target.value);
  }
  const [redPipeSeconds, setRedPipeSeconds] = React.useState(1);
  const handleSetRedPipe = (e) => {
    setRedPipeSeconds(e.target.value);
  }
  const [bluePipeSeconds, setBluePipeSeconds] = React.useState(90);
  const handleSetBluePipe = (e) => {
    setBluePipeSeconds(e.target.value);
  }
  const [greenPipeSeconds, setGreenPipeSeconds] = React.useState(60);
  const handleSetGreenPipe = (e) => {
    setGreenPipeSeconds(e.target.value);
  }
  const [purplePipeSeconds, setPurplePipeSeconds] = React.useState(45);
  const handleSetPurplePipe = (e) => {
    setPurplePipeSeconds(e.target.value);
  }
  const [goldPipeSeconds, setGoldPipeSeconds] = React.useState(300);
  const handleSetGoldPipe = (e) => {
    setGoldPipeSeconds(e.target.value);
  }
  const [shoudUseGoldPipe, setShouldUseGoldPipe] = React.useState(false);
  const handleSetUseGoldPipe = (e) => {
    setShouldUseGoldPipe(!e.target.checked);
  }
  const socketRef = React.useRef(null);
  
  const [color, setColor] = React.useState();
  const handleColorChange = (color) => {
    socketRef.current.emit("message", {
      subject: "setcolor",
      target: color.hex
    })
    setColor(color)
  }
  const handleDropDebug = () => {
    socketRef.current.emit("testdebugsub", debugCount)
  }
  const handleConnect = () => {
    const socket = io("https://newgpbtimer.mariotimeline.com/",{
      query: {
        token: encodeURIComponent(password)
      }
    });
    socket.on("connect_error", (err)=>{
      socketRef.current = null;
      setErrorMsg(err.message);
    })
    socket.on("secureclient", (isSecureClient)=>{
      if(!isSecureClient){
        socketRef.current = null;
        window.location = "https://www.youtube.com/watch?v=xvFZjo5PgG0"
      }
    }) 
    socketRef.current = socket;
    setErrorMsg("");
    setPassword("");
  }
  const handleSaveWheel = () => {
    console.log("executing save")
    socketRef.current.emit("message", {
      subject: "setusewheel",
      value: shoudUseGoldPipe ? 0 : 1
    });
    socketRef.current.emit("message", {
      subject: "setwheelvalue_5",
      value: parseInt(mainJackpot)
    });
    socketRef.current.emit("message", {
      subject: "setwheelvalue_4",
      value: parseInt(secondJackpot)
    });
    socketRef.current.emit("message", {
      subject: "setwheelvalue_3",
      value: parseInt(thirdJackpot)
    });
    socketRef.current.emit("message", {
      subject: "setwheelvalue_2",
      value: parseInt(fourthJackpot)
    });
    socketRef.current.emit("message", {
      subject: "setwheelvalue_1",
      value: parseInt(fifthJackpot)
    });
  }
  const handleSave = () => {
    console.log("executing save")
    socketRef.current.emit("message", {
      subject: "red",
      value: parseInt(redPipeSeconds)
    });
    socketRef.current.emit("message", {
      subject: "green",
      value: parseInt(greenPipeSeconds)
    });
    socketRef.current.emit("message", {
      subject: "blue",
      value: parseInt(bluePipeSeconds)
    });
    socketRef.current.emit("message", {
      subject: "purple",
      value: parseInt(purplePipeSeconds)
    });
    socketRef.current.emit("message", {
      subject: "gold",
      value: parseInt(goldPipeSeconds)
    });
  }
  const handleSetTime = () => {
    const hours =  parseInt(startHours);
    const minutes = parseInt(startMinutes)
    const seconds = parseInt(startSeconds)

    let total = hours * 60 * 60 * 1000;
    total += minutes * 60 * 1000;
    total += seconds * 1000;

    socketRef.current.emit("message", {
      subject: "settime",
      value: total
    })
  }
  
  const handlePause = () => {
    socketRef.current.emit("message", {
      subject:"pause"
    })
  }

  const handleStart = () => {
    socketRef.current.emit("message", {
      subject:"start"
    })
  }
  return (
    <div className="App">
      <Row>
        <Col md={2}>&nbsp;</Col>
        <Col md={8}>
          <h1>Plinko Config</h1>
          <Form>
            {!socketRef.current && 
              <>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" value={password} onChange={handleSetPassword}/>
                  <Form.Text className="text-danger">
                  {errorMsg}
                  </Form.Text>
                </Form.Group>
                <Button variant="primary" onClick={handleConnect}>
                  Connect
                </Button>
              </>
            }
            {socketRef.current && 
              <>
              <Row>
                <Col md={6}>
                  <h2>Set Pipes Worth</h2>
                  <Form.Group className="my-3" controlId="formBasicPassword">
                    <Form.Label>Blue Pipe</Form.Label>
                    <Form.Control type="number" value={bluePipeSeconds} onChange={handleSetBluePipe}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Green Pipe</Form.Label>
                    <Form.Control type="number" value={greenPipeSeconds} onChange={handleSetGreenPipe}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Purple Pipe</Form.Label>
                    <Form.Control type="number" value={purplePipeSeconds} onChange={handleSetPurplePipe}/>
                  </Form.Group>
                  <Form.Group className="my-3" controlId="formBasicPassword">
                    <Form.Label>Red Pipe</Form.Label>
                    <Form.Control type="number" value={redPipeSeconds} onChange={handleSetRedPipe}/>
                  </Form.Group>
                  <Form.Group className="my-3" controlId="formBasicPassword">
                    <Form.Label>Gold Pipe</Form.Label>
                    <Form.Control type="number" value={goldPipeSeconds} onChange={handleSetGoldPipe}/>
                  </Form.Group>
                  <Button variant="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Col>
                <Col md={6}>
                  <h2>Set Wheel Worth</h2>
                  <Form.Check type="checkbox" label="Enable Wheel" onChange={handleSetUseGoldPipe} checked={!shoudUseGoldPipe}/>
                  <Form.Group className="my-3" controlId="formBasicPassword">
                    <Form.Label>Main Jackpot</Form.Label>
                    <Form.Control type="number" value={mainJackpot} onChange={handleSetMainJackpot}/>
                  </Form.Group>
                  <Form.Group className="my-3" controlId="formBasicPassword">
                    <Form.Label>Side Jackpot 2</Form.Label>
                    <Form.Control type="number" value={secondJackpot} onChange={handleSetSecondJackpot}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Side Jackpot 3</Form.Label>
                    <Form.Control type="number" value={thirdJackpot} onChange={handleSetThirdJackpot}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Side Jackpot 4</Form.Label>
                    <Form.Control type="number" value={fourthJackpot} onChange={handleSetFourthJackpot}/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Side Jackpot 5</Form.Label>
                    <Form.Control type="number" value={fifthJackpot} onChange={handleSetFifthJackpot}/>
                  </Form.Group>
                  <Button variant="primary" onClick={handleSaveWheel}>
                    Save
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Test Drop Count</Form.Label>
                    <Form.Control type="number" value={debugCount} onChange={handleSetDebugCount}/>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Label>Test Drop</Form.Label>
                  <Button variant="primary" className='w-100' onClick={handleDropDebug}>
                    Drop Chips
                  </Button>
                </Col>
              </Row>
              </>
            }
          </Form>
          { socketRef.current && 
          <>
            <h2>Set Timer</h2>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Hours</Form.Label>
                  <Form.Control type="number" value={startHours} onChange={handleSetStartHours}/>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Minutes</Form.Label>
                  <Form.Control type="number" value={startMinutes} onChange={handleSetStartMinutes}/>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Seconds</Form.Label>
                  <Form.Control type="number" value={startSeconds} onChange={handleSetStartSeconds}/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button variant="primary" className='w-100' onClick={handleShow}>
                  Set Timer
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Are you want to sure you want to set the timer?</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="danger" onClick={() =>{
                      handleClose();
                      handleSetTime();
                    }}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
              <Col md={4}>
                <Button variant="primary" className='w-100' onClick={handleStart}>
                  Start
                </Button>
                <h2>Set Timer Color</h2>
                <SketchPicker color={color} onChange={handleColorChange} className="w-75"/>
              </Col>
              <Col md={4}>
                <Button variant="primary" className='w-100' onClick={handlePause}>
                  Pause
                </Button>
              </Col>
            </Row>
                  </>}
        </Col>
        <Col md={2}>&nbsp;</Col>
      </Row>
    </div>
  );
}

export default App;
